<template>
  <div style="margin-left: 2rem">
    <p>Please take a moment to give us feedback or raise issues on:</p>
    <a
      style="color: #0000ee"
      href="https://github.com/thunderbird/send-suite/issues/new?labels=feedback-alpha&title=Alpha+Feedback:+&body=Your+description+here:"
    >
      Github issues
    </a>
    <a style="color: #0000ee" href="https://forms.gle/qBYAjSRwMiTQ6KNr6">
      <p>Google forms</p>
    </a>
  </div>
</template>
