<script setup lang="ts">
import Btn from '@/apps/lockbox/elements/BtnComponent.vue';
import { formatSessionInfo, mozAcctLogin } from '@/lib/fxa';
import { dbUserSetup } from '@/lib/helpers';
import { CLIENT_MESSAGES } from '@/lib/messages';
import useApiStore from '@/stores/api-store';
import useKeychainStore from '@/stores/keychain-store';
import useUserStore from '@/stores/user-store';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useFolderStore from './stores/folder-store';

const { api } = useApiStore();
const { user } = useUserStore();
const userStore = useUserStore();
const { keychain } = useKeychainStore();
const folderStore = useFolderStore();

const router = useRouter();

const sessionInfo = ref(null);

async function pingSession() {
  const session = await api.call<null | string>(`users/me`);

  sessionInfo.value = session ?? CLIENT_MESSAGES.SHOULD_LOG_IN;

  if (sessionInfo.value) {
    router.push('/lockbox/profile');
  }
}

async function onSuccess() {
  await dbUserSetup(userStore, keychain, folderStore);
  await pingSession();
}
</script>
<template>
  <p v-if="user?.id">Your session has expired, please log back in</p>
  <br />
  <Btn @click.prevent="mozAcctLogin(onSuccess)">Log into Moz Acct</Btn>
  <br />
  <Btn @click.prevent="pingSession">ping session</Btn>
  <br />
  <pre v-if="sessionInfo">
    {{ formatSessionInfo(sessionInfo) }}
  </pre>
</template>
